import * as React from 'react';
import Container from '@mui/material/Container';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ProtectedRoute from './components/protected-route/protected-route';
import Navbar from './components/navbar/navbar';
import Posts from './pages/posts/posts';
import Auth from './pages/auth/auth';
import Logout from './pages/auth/logout';
import WaterUsage from './pages/water-usage/water-usage';
import DeviceList from './pages/devices/device-list/device-list';
import DeviceDetails from './pages/devices/device-details/device-details';
import DailyHealthLog from './pages/daily-health-log/daily-health-log';
import Notification from './components/notification/notification';
import ExpenseManager from './pages/expense-manager/expense-manager';
import UploadFiles from './pages/expense-manager/upload-files/upload-files';
import ReviewTransactions from './pages/expense-manager/review-transactions/review-transactions';
import Categories from './pages/expense-manager/categories/categories';
import { styled } from '@mui/material/styles';
import LoadingSpinner from './components/loading-spinner/loading-spinner';
import ReportByCategory from './pages/expense-manager/reports/report-by-category';
import TimelineOnCategory from './pages/expense-manager/reports/timeline-on-category';

const AppContainer = styled(Container)(() => ({
  border: '0 dashed red',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 64px)',
  height: 'auto',
}));

function About() {
  return <h2>About</h2>;
}

function Users() {
  return (
    <>
      <h2>Users</h2>

      <div
        style={{
          width: '90%',
          height: '300px',
          border: '1px solid red',
        }}
      >
        <button>Submit test</button>
      </div>
    </>
  );
}

function NotFound() {
  return <h2>NotFound</h2>;
}

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <AppContainer maxWidth="lg">
            <Routes>
              <Route
                path="/about"
                element={
                  <ProtectedRoute>
                    <About />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/posts"
                element={
                  <ProtectedRoute>
                    <Posts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/water-usage"
                element={
                  <ProtectedRoute>
                    <WaterUsage />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/daily-health-log"
                element={
                  <ProtectedRoute>
                    <DailyHealthLog />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/device-list"
                element={
                  <ProtectedRoute>
                    <DeviceList />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/devices/:id"
                element={
                  <ProtectedRoute>
                    <DeviceDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/expense-manager"
                element={
                  <ProtectedRoute>
                    <ExpenseManager />
                  </ProtectedRoute>
                }
              >
                <Route index element={<UploadFiles />} />
                <Route path="upload-files" element={<UploadFiles />} />
                <Route path="review-transactions" element={<ReviewTransactions />} />
                {/* <Route path="reports" element={<Reports />} /> */}
                <Route path="reports/report-by-category" element={<ReportByCategory />} />
                <Route path="reports/timeline-on-category" element={<TimelineOnCategory />} />
                <Route path="categories" element={<Categories />} />
              </Route>
              <Route
                exact
                path="/logout"
                element={
                  <ProtectedRoute>
                    <Logout />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<Auth />} />
              <Route path="/" element={<Auth />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AppContainer>
        </div>
      </Router>
      <Notification />
      <LoadingSpinner />
    </div>
  );
}

export default App;
