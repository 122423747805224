import axios from 'axios';

const rootUrls = {
  prod: 'https://auth-service.waltair.io/api',
  dev: 'https://auth-service.waltair.io/api',
  // dev: 'http://localhost:5050/api',
};

export const api = {
  ROOT_URL: process.env.NODE_ENV === 'production' ? rootUrls.prod : rootUrls.dev,
  EXPENSES_API_ROOT_URL:
    process.env.NODE_ENV === 'production'
      ? 'https://web4.kamidi.com/data-service/api/expense-manager'
      : 'http://localhost:5300/api/expense-manager',
  HEALTH_LOG_API_ROOT_URL:
    process.env.NODE_ENV === 'production'
      ? 'https://web4.kamidi.com/data-service/api/daily-health-log'
      : 'http://localhost:5300/api/daily-health-log',
  DEVICE_INFO_API_ROOT_URL:
    process.env.NODE_ENV === 'production'
      ? 'https://ball-machine.waltair.io/api/v2'
      : 'http://localhost:5200/api/v2',
};

export const csrfTokenHeaderName = 'auth-sec-token';

const axiosApi = axios.create({
  baseURL: api.ROOT_URL,
  withCredentials: true,
});

axiosApi.interceptors.request.use(
  function (config) {
    config.headers[csrfTokenHeaderName] = localStorage.getItem(csrfTokenHeaderName);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axiosApi.interceptors.response.use(
  function (response) {
    if (response.headers[csrfTokenHeaderName]) {
      localStorage.setItem(csrfTokenHeaderName, response.headers[csrfTokenHeaderName]);
    }

    return response;
  },
  function (error) {
    if (error.response.status === 401 && error.response.config.url !== '/users/login') {
      localStorage.setItem(csrfTokenHeaderName, '');
      window.location = '/login';
    } else {
      return Promise.reject(error);
    }
  },
);

export default axiosApi;
