import React, { useCallback, useContext, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import DeviceInfoCard from '../device-info-card/device-info-card';
import lodash from 'lodash';
import {
  useParams,
} from 'react-router-dom';
import {
  GET_DEVICE_DETAILS,
  OPERATE_MOTORS,
  getDeviceDetailsSuccess,
} from '../devices.reducer';
import BusyLoaderSimple from '../../../components/loading-spinner/busy-loader-simple';
import Link from '@mui/material/Link';
import { SocketContext } from '../../../context/socket';
import Typography from '@mui/material/Typography';
import StartIcon from '@mui/icons-material/Start';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import MoveUpIcon from '@mui/icons-material/MoveUp';

const DeviceDetails = () => {
  const params = useParams();
  const socket = useContext(SocketContext);
  const deviceId = params.id || null;
  const dispatch = useDispatch();
  const {
    deviceDetails, actionInProgress,
  } = useSelector((state) => state.deviceInfo);

  const handleDeviceDetails = useCallback((data) => {
    dispatch(getDeviceDetailsSuccess({ data: { deviceDetails: { ...data } } }));
  }, []);

  if (socket.connected) {
    setTimeout(() => {
      socket.emit('device-details-request', { deviceId });
    }, 10000);
  }

  const handleOperateMotors = useCallback((deviceId, command) => {
    dispatch({
      type: OPERATE_MOTORS,
      payload: {
        deviceId,
        command,
      },
    });
  }, [
    dispatch,
    deviceId
  ]);

  useEffect(() => {
    if (!deviceId) {
      return;
    }
    dispatch({
      type: GET_DEVICE_DETAILS,
      payload: {
        deviceId
      },
    });
  }, [deviceId, dispatch]);

  useEffect(() => {
    socket.on('device-details', handleDeviceDetails);

    return () => {
      socket.off('device-details', handleDeviceDetails);
    };
  }, [deviceId, socket, handleDeviceDetails]);

  return (<Stack direction="column" spacing={2} mb="2rem">
    <Box
      sx={{
        textAlign: 'center', marginBottom: '2rem'
      }}
    >
      <h1>Device Details</h1>
    </Box>
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={2}
    >
      <Link href="/device-list" underline="hover">
        {'Back to Device List'}
      </Link>
    </Stack>
    {
      deviceDetails && deviceDetails.device_id && (
        <Card variant="outlined" sx={{ marginBottom: '2rem', padding: '1rem' }}>
          <Typography variant="h5" component="div" mb="2rem" color="grey">
            {'Send Commands To Device'}
          </Typography>
          <Stack spacing={2} direction="row">
            <Button variant="contained"
                    disabled={deviceDetails.current_command === 'start_motors' ||
                      deviceDetails.current_command === 'move_motors_test'}
                    endIcon={<StartIcon/>}
                    onClick={() => handleOperateMotors(deviceId, 'start_motors')}
            >
              {'Start motors'}
            </Button>
            <Button variant="contained"
                    disabled={deviceDetails.current_command === 'stop_motors'}
                    endIcon={<StopCircleIcon/>}
                    onClick={() => handleOperateMotors(deviceId, 'stop_motors')}
            >
              {'Stop motors'}
            </Button>
            <Button variant="contained"
                    disabled={deviceDetails.current_command === 'stop_motors'}
                    endIcon={<MoveUpIcon/>}
                    onClick={() => handleOperateMotors(deviceId, 'move_motors_test')}
            >
              {'Test run motors'}
            </Button>
          </Stack>
        </Card>
      )
    }
    {
      actionInProgress ? <BusyLoaderSimple/> : (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} md={12} lg={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                >
                  <DeviceInfoCard title={'General Info'}
                                  data={lodash.pick(deviceDetails, ['device_id', 'host_name'])}/>
                  <DeviceInfoCard title={'User Session Info'}
                                  data={lodash.pick(deviceDetails, ['current_auth_code',
                                    'current_auth_code_created_at',
                                    'previous_auth_code',
                                    'active_user_session_id',
                                    'last_user_session_end_time'])}/>
                  <DeviceInfoCard title={'CPU Info'} data={deviceDetails.cpu_info}/>
                  <DeviceInfoCard title={'Network Devices'} data={deviceDetails.network_devices}/>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                >
                  <DeviceInfoCard title={'Facility Details'}
                                  data={lodash.pick(deviceDetails, ['facility_id', 'facility_name'])}/>
                  <DeviceInfoCard title={'Memory Info'} data={deviceDetails.memory_info}/>
                  <DeviceInfoCard title={'Temperature Info'} data={deviceDetails.temperature_info}/>
                  <DeviceInfoCard title={'Timestamps'}
                                  data={lodash.pick(deviceDetails, ['last_report_timestamp', 'updated_timestamp'])}/>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )
    }
  </Stack>);
};

export default DeviceDetails;
