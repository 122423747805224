/* eslint-disable no-console */
import React from 'react';
import io from 'socket.io-client';

// const deviceList = [];
// const deviceDetails = {};

function getRandomActionParameters(action) {
  if (action === 'toggle_ball_launcher') {
    const value = Math.random() > 0.5 ? 'on' : 'off';
    return {
      actionParameters: { value }
    };
  }

  if (action === 'change_ball_delivery_interval') {
    // get a random value between 2 and 10
    const value = Math.floor(Math.random() * 9) + 2;
    return {
      actionParameters: { value }
    };
  }

  if (action === 'rally_and_recover_mode') {
    const value = Math.random() > 0.5 ? 1 : 0;
    const recoveryTime = Math.floor(Math.random() * 20) + 20;
    const rallyCount = Math.floor(Math.random() * 10) + 6;

    return {
      actionParameters: { enable: value, recovery_time: recoveryTime, rally_count: rallyCount }
    };
  }

  if (action === 'active_player_practice_program') {
    const programs = ['forehand', 'backhand', 'volley', 'overhead_smash', 'forehand_backhand_practice'];
    const program = programs[Math.floor(Math.random() * programs.length)];
    return {
      actionParameters: { value: program }
    };
  }
}

function getRandomAction() {
  // Array of possible actions
  const actions = [
    'toggle_ball_launcher',
    'change_ball_delivery_interval',
    'rally_and_recover_mode',
    'active_player_practice_program'
  ];

  // Generate a random index based on the length of the actions array
  const randomIndex = Math.floor(Math.random() * actions.length);

  return actions[randomIndex];
}

function generateRandomData() {
  const action = getRandomAction();
  const actionParameters = getRandomActionParameters(action);
  return {
    action,
    ...actionParameters
  };
}

const SOCKET_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'https://web4.kamidi.com/player-session' // 'https://dev-ball-machine.waltair.io/player-session' // 'http://192.168.86.52:5200/player-session'
    : 'https://web4.kamidi.com/player-session';

// export const WebsocketContext = createContext(null);

// eslint-disable-next-line react/prop-types
/*
const WebsocketProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);

  const options = useMemo(() => ({}), []);

  useEffect(() => {
    try {
      const socketConnection = io(SOCKET_URL, {
        transports: ['websocket', 'polling'],
        cors: {
          origin: 'http://localhost:3000',
        },
      });
      setConnection(socketConnection);
    } catch (err) {
      console.log(err);
    }
  }, [options]);

  return (
    <WebsocketContext.Provider value={connection}>
      {children}
    </WebsocketContext.Provider>
  );
};
*/

export const socket = io(SOCKET_URL, {
  transports: ['websocket', 'polling', 'flashsocket'],
  cors: {
    origin: ['http://localhost:3000', 'https://admin.waltair.io'],
  },
  auth: {
    token: 'nucbox-09',
    session_id: 'nucbox-10',
    device_id: '78b07016-feed-4ea1-bf2f-1729fa7ccfe9',
  }
});

socket.on('connect', () => {
  console.log('connected to socket ID:', socket.id);

  if (socket.connected) {
    /* console.log('Socket - sending device-list-request');
    setInterval(() => {
      socket.emit('device-list-request');
    }, 5000); */
    // let counter = 0;
    setInterval(() => {
      console.log('Socket - sending live_stats request');
      if (socket.connected) {
        // socket.emit('live_stats', { machine_id: 'nucbox', clientSocketId: socket.id });
        /* if (counter > 3) {
          console.log('Socket - disconnecting');
          socket.disconnect();
          counter = 0;
        } */
      }

      const randomData = generateRandomData();
      console.log('Socket - sending change_program_params:', randomData);
      socket.emit('change_program_params', {
        ...randomData
      }, (data) => {
        console.log('change_program_params callback:', data);
      });
    }, 15000);
  }
});

socket.on('disconnect', (reason) => {
  console.log('Socket connection status:', socket.connected);
  console.log('Socket connection lost. reason:', reason);
  socket.connect();
});

socket.on('connect_error', (err) => {
  console.log(`connect_error due to ${err.message}`);
});

socket.on('play_session_live_stats', (data) => {
  console.log('Socket - play_session_live_stats:', data);
});

socket.on('app_vars_latest', (data) => {
  console.log('Socket - app_vars_latest:', { ...data });
});

export const SocketContext = React.createContext(null);
/* export const DeviceContext = React.createContext({
  deviceList,
  deviceDetails,
  setDeviceList: (data) => {
    console.log('SocketContext setDeviceList incoming data:', data);
    deviceList = data;
  },
  setDeviceDetails: (data) => {
    console.log('SocketContext setDeviceDetails incoming data:', data);
    deviceDetails = data;
  },
}); */
