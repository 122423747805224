import axiosApi, { api } from '../../helpers/api-helper';

export const apiGetDeviceList = async () => {
  return await axiosApi.get(`${api.DEVICE_INFO_API_ROOT_URL}/devices`);
};

export const apiGetDeviceDetails = async ({ deviceId }) => {
  return await axiosApi.get(`${api.DEVICE_INFO_API_ROOT_URL}/device/${deviceId}`);
};

export const apiSetDeviceCommand = async ({ deviceId, command }) => {
  return await axiosApi.post(`${api.DEVICE_INFO_API_ROOT_URL}/device-command`, {
    id: deviceId,
    command,
  });
};
